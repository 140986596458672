/* eslint-disable no-undef */
import { defineStore } from 'pinia'
import { isEqual, isEqualWith, debounce, compact, has } from 'lodash'
import { watchPausable } from '@vueuse/core'
import { isEmpty } from '#imports'

/**
 * Handle drafts.
 *  
 * The `showingDialog` member is observed by `~/components/DraftFoundDialog.vue`
 * which must be manually mounted somewhere. Currently mounted in `PortalEditPage`,
 * but if you're not using that you'll have to mount it yourself.
 */
export const useDraftsStore = defineStore('drafts', () => {

  const portal = usePortalStore();

  const drafts = ref([]);
  const draft = ref({});
  const showingDialog = ref(false);
  const options = ref({});
  const listening = ref(false);
  const ticker = ref(null);
  const pending = ref(false);
  let item, pausable, debouncedOnItemChange;

  const groupKey = computed(() => options.value.groupKey);
  const itemKey = computed(() => options.value.itemKey);
  const loadedDraft = computed(() => !! draft.value?.id);
  const active = computed(() => !! groupKey.value);
  const timeout = computed(() => options.value.timeout ?? useRuntimeConfig().public.draftDelay);

  /**
   * Should be called on each new page that loads drafts.
   * 
   * @param {Object} options 
   * @param {Ref} itemRef optional item reference, not used for index pages
   */
  function init(_options, _item = null) {
    
    console.debug('[draft] init', _options, toValue(_item), timeout.value)
    
    $reset();
    item = _item;
    
    options.value = {
      //can users cancel the 'draft found' dialog?
      cancellable: false,
      //what is the item called? (ie the type of entity)
      itemLabel: "form",  
      //how wide is the dialog
      dialogWidth: "65%",
      //check drafts and start listening straight away
      immediate: true,
      //debounce the watcher (defaults to .env value)
      timeout: undefined,
      //if the item contains these keys, ignore it
      ignoreKeys: ['__blank'],
      //the user-supplied options to override defaults
      ..._options
    };

    sanityCheck();

    if (options.value.immediate) {
      checkDrafts();
    }

    if (item) {
      console.debug('[draft] init() item is supplied', toValue(item))
      if (options.value.immediate) {
        listen();
      }
    } else {
      console.debug('[draft] init() item was not supplied')
    }
   
  }

  function start() {
    console.debug('[draft] start() (caller manually initiated listen)');
    listen();
  }

  function listen() {
    console.debug(`[draft] listen()`);
    createWatcher();
    listening.value = true;
  }

  function pause() {
    console.debug('[draft] pause()');
    listening.value = false;
    pausable?.pause();
  }

  function resume(newState = undefined) {
    console.debug('[draft] resume()');
    listening.value = true;
    pausable?.resume();
    if (newState) {
      draft.value.data = toValue(newState);
    }
  }

  function stop() {
    console.debug('[draft] stop()');

    if (debouncedOnItemChange) {
      console.debug('[draft] stop() cancelling pending item change calls');
      debouncedOnItemChange.cancel();
    } 

    if (pausable) {
      console.debug('[draft] stop() stopping pausable watcher');
      pausable.stop();
    }
    
    listening.value = false;
    debouncedOnItemChange = pausable = null;
  }

  /**
   * Create the watcher for the item.
   */
  function createWatcher() {

    //a debounced function to call onItemChange
    if (! debouncedOnItemChange) {
      debouncedOnItemChange = debounce(() => {
        console.log('[draft] debouncedOnItemChange', timeout.value);
        /**
         * TODO: @see https://www.joren.co/autosave-forms-with-the-vuejs-composition-api/
         * 
         * If a draft save takes a long time, later saves may be overwritten by earlier ones.
         * Need to make sure that a change cancels all previous saves, but maybe we could do this
         * with record versioning.
         */
        onItemChange();
      }, timeout.value);

    } else {
      console.debug('[draft] createWatcher() debouncedOnItemChange already exists');
    }

    //a pausable watcher for the item
    if (! pausable) {
      pausable = watchPausable(
        item,
        debouncedOnItemChange,
        { deep: true }
      );
    } else {
      console.debug('[draft] createWatcher() pausable already exists');
    } 

  }

  /**
   * Check for drafts and begin listening for changes.
   */
  async function checkDrafts() {

    const route = useRoute();
    console.debug('[draft] checkDrafts() checking route for draft id', route.path, route.query);

    //do we have any drafts for our group and item key?
    await fetchDrafts();
    console.debug(`[draft] checkDrafts() loaded ${drafts.value.length} draft(s)`)

    if (drafts.value.length > 0) {
      if (route.query.draft) {
        //a specific draft has been requested, apply it now
        const found = drafts.value.find(e => e.id == route.query.draft)
        if (found) {
          loadDraft(found);
          console.debug('[draft] loaded requested draft', found);
        } else {
          console.error('[draft] no such draft found!', route.query.draft);
        }
      } else {
        //ask user to choose one of the drafts or start a new one
        clearDraft();
        showingDialog.value = true;
      }
    }
  }

  /**
   * Load any drafts for the supplied group
   * key and optional item key (set in init())
   */
  async function fetchDrafts() {

    console.debug('[draft] fetchDrafts()');

    const query = {
      group_key: groupKey.value,
      key: itemKey.value
    }
    const extra = { 
      narrative: 'Checking for drafts...',
    }

    //useAsyncData will cancel previous request if a new one comes in,
    //this is needed in case a delayed index response comes in after a form response
    pending.value = true;
    try {
      const { data } = await useAsyncData('drafts', () => portal.get('/drafts', query, extra));
      drafts.value = data.value.drafts;
    } finally {
      pending.value = false;
    }

    return drafts;

  }

  /**
   * Main API for notifying of item changes.
   */
  function onItemChange() {

    console.debug('[draft] onItemChange()', toValue(item));

    if (! toValue(item)) {
      console.debug('[draft] blank item ref')
      //happens when index sets item back to undefined
      return false;
    }

    if (! usePageStore().dirty) {
      console.debug('[draft] onItemChange() page is not dirty, ignoring')
      return false;
    }

    if (shouldIgnore(item)) {
      console.debug('[draft] item is still initial, not comparing');
      return false;
    }

    if (! listening.value) {
      console.debug("[draft] onItemChange: I'm not listening!!!!");
      return false
    }

    if (showingDialog.value) {
      console.debug('[draft] onItemChange: dialog showing, ignoring')
      return false;
    }

    if (objectsEqual(item.value, draft.value.data)) {
      console.debug('[draft] onItemChange: item and draft are the same, ignoring')
      return false;
    }

    console.debug('[draft] onItemChange: item and previous item differ, saving draft');
    return saveDraft();

  }

  /**
   * When item is saved, delete the current draft.
   */
  async function onSavedItem() {
    console.debug('[draft] onSavedItem()');

    if (! draft.value.id) {
      console.debug('[draft] onSavedItem() no draft to delete');
      return false;
    }

    //TODO: check same as item

    await deleteDraft(draft.value.id);
    return true;
  }

  /**
   * Save a draft.
   * 
   * Should usually be handled by calling onItemChange().
   */
  async function saveDraft() {

    pause();

    try {

      console.debug('[draft] saveDraft()');
      const data = deepClone(item);

      if (isEmpty(data)) {
        console.error("[draft] Tried to save draft for blank item!");
        return false;
      }

      ticker.value = `Saving draft...`;

      const id = draft.value.id ?? '';
      const method = id ? 'patch' : 'post';

      updateDraftTitle();

      const payload = { 
        draft: {
          ...draft.value,
          key: itemKey.value,
          group_key: groupKey.value,
          data
        }
      }
      
      console.debug(`[draft] *** SAVING DRAFT [${draft.value.id ?? '(new)'}] (group[${groupKey.value}], key[${itemKey.value}])`, payload);
      const response = await portal[method](`/drafts/${id}`, payload, { narrative: 'Saving draft...' });

      if (! draft.value.record_version || response.draft.record_version > draft.value.record_version) {
        draft.value = {
          ...response.draft, 
          //sometimes the server changes the draft data (ie compacting lists containing nulls),
          //so ensure draft data matches local item exactly to prevent listener firing
          data 
        }
      } else {
        console.warn('[draft] saveDraft() server draft is older than local draft', response.draft, draft.value);
      }

      ticker.value = `Draft #${draft.value.id} saved at ${formatDate(draft.value.updated_at)}`;

      //add or update our draft in the drafts list
      const index = drafts.value.findIndex(e => e.id == draft.value.id);
      if (-1 === index) {
        drafts.value.unshift(draft.value);
      } else {
        drafts.value.splice(index, 1, draft.value);
      }

      return true;

    } finally {
      resume();
    }

  }

  function updateDraftTitle() {
    const id = draft.value.id;
    draft.value.title ||= item?.value?.title || (id ? `Draft #${id}` : `New Draft ${formatDate(Date.now())}`);
  }

  /**
   * Set the current draft, and apply it to the item.
   * 
   * @param {Object} draft 
   * @param {Boolean} showMessage 
   * @param {String} draftMessage 
   */
  function loadDraft(_draft, showMessage = true, draftMessage = undefined) {
    
    pause();

    try {

      console.debug(`[draft] loadDraft(${_draft.id})`);

      //apply the draft
      draft.value = _draft;
      Object.assign(item.value, {}, draft.value.data);
      
      if (showMessage) {
        useMessageStore().info(draftMessage ?? `Draft #${draft.value.id} has been loaded.`);
      }

      ticker.value = `Loaded draft #${draft.value.id}`;

    } finally {
      resume();
    }

  }

  /**
   * Calls clearDraft() and starts listener.
   */
  function newDraft() {
    console.debug('[draft] creating new draft...')
    clearDraft();
    if (! listening.value) {
      listen();
    }
  }

  /**
   * Unset the current draft.
   */
  function clearDraft() {
    console.debug('[draft] clearing draft...')
    draft.value = {};
    updateDraftTitle();
  }

  /**
   * Delete a draft.
   * 
   * @param {Integer|Object} draftId
   */
  async function deleteDraft(draftId) {

    if (draftId.id) {
      draftId = draftId.id;
    }

    if (! draftId) {
      console.debug('[draft] deleteDraft() no draft id supplied');
      return false;
    }

    pause();

    try {

      ticker.value = 'Deleting draft...';
      console.debug("[draft] deleting draft", draftId);
      await portal.delete(`/drafts/${draftId}`, { narrative: `Deleting draft #${draftId}...` });

      if (draft.value.id == draftId) {
        console.debug(`[draft] deleteDraft(${draftId}) matches current draft, clearing it`);
        clearDraft();
      }

      const index = drafts.value.findIndex(e => e.id == draftId);
      if (index !== -1) {
        drafts.value.splice(index, 1);
      }

      ticker.value = null;

    } finally {
      resume();
    }

  }

  /**
   * Caller may set flags in the model item (ie __blank)
   * to indicate that it is still pending initial load
   * from the server.
   * 
   * @param {Object} obj
   */
  function shouldIgnore(obj) {

    const val = toValue(obj)

    for (const key of options.value.ignoreKeys) {
      if (has(val, key)) {
        console.debug(`[draft] shouldIgnore found key '${key}, ignoring item`);
        return true;
      }
    }

    return false;
  }

  /**
   * Called by listener before most events.
   */
  function sanityCheck() {
    if (isEmpty(groupKey.value)) {
      console.error('A group key has not been set!')
    }
  }

  function objectsEqual(v1, v2) {
    
    const c1 = toRaw(toValue(v1));
    const c2 = toRaw(toValue(v2));

    const value = isEqualWith(c1, c2, (a, b) => {
      //strip null values from arrays, because some
      //forms might add a default entry if field is
      //required (eg dspace does this); this won't
      //catch when they add a blank object though
      if (Array.isArray(a) && Array.isArray(b)) {
        return isEqual(compact(a), compact(b))
      }
      //defer to normal handler
      return undefined;
    });
    
    return value;
  }

  /**
   * Clear the store.
   * 
   * Called in a middleware so that each page change
   * must init the draft store with new keys.
   */
  function $reset() {
    console.debug('[draft] $reset()')
    stop();
    drafts.value = [];
    draft.value = {};
    showingDialog.value = false;
    options.value = {};
    listening.value = false;
    pending.value = false;
    ticker.value = null;
    item = null;
  }

  return {
    drafts,
    draft,
    showingDialog,
    options,
    ticker,
    listening,
    active,
    pending,
    loadedDraft,
    item,
    init,
    checkDrafts,
    saveDraft,
    start,
    pause,
    resume,
    deleteDraft,
    loadDraft,
    newDraft,
    onItemChange,
    onSavedItem,
    shouldIgnore,
    $reset
  }

});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDraftsStore, import.meta.hot));
}